// Import the Axios library for making HTTP requests
import axios from "axios";
import { nodeUrl } from "constant"

const alternativeCarriersApi = async () => {
    try {
        const response = await axios.get(`${nodeUrl}alternative-carriers-list`);
        return response?.data;
    } catch (error: any) {
        throw error;
    }
};

const getKeyMetricsDateApi = async (payload: any) => {
    try {
        const response = await axios.post(`${nodeUrl}get-metrics`, payload);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};


const getListOfAllLanesByShipmentsApi = async (payload: any) => {
    try {
        const response = await axios.post(`${nodeUrl}list-of-all-lanes-by-shipments`, payload);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

const getLanesByFuelUsageAndMileageApi = async (payload: any) => {
    try {
        const response = await axios.post(`${nodeUrl}get-lanes-by-fuel-usage-and-mileage`, payload);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

const getStackedGraphByLaneAndFuelTypeApi = async (payload: any) => {
    try {
        const response = await axios.post(`${nodeUrl}get-total-data-by-lane-and-fuel-type`, payload, { headers: { deniedCancle: true } });
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

const getTotalEmissionGraphByLaneAndFuelTypeApi = async (payload: any) => {
    try {
        const response = await axios.post(`${nodeUrl}get-total-emission-by-fuel-type`, payload);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

const getLaneStatisticsApi = async (payload: any) => {
    try {
        const response = await axios.post(`${nodeUrl}get-lane-statistics`, payload);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

// Create an object that contains the project-related service functions
const localFreightService = {
    alternativeCarriersApi,
    getKeyMetricsDateApi,
    getListOfAllLanesByShipmentsApi,
    getLanesByFuelUsageAndMileageApi,
    getStackedGraphByLaneAndFuelTypeApi,
    getTotalEmissionGraphByLaneAndFuelTypeApi,
    getLaneStatisticsApi
};

// Export the projectService object for use in other parts of the application
export default localFreightService;
