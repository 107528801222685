import axios from 'axios';
import { actUrl, adminUrl } from "constant"

// Method for updating the image user's profile.
const getRoleListApi = async (data: any) => {
    try {
        // Send a POST request to the "profile-update" endpoint with the provided data and token.
        const response = await axios.post(`${adminUrl}get-role-data`, data);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}

const deleteRoleApi = async (data: any) => {
    try {
        // Send a POST request to the "profile-update" endpoint with the provided data and token.
        const response = await axios.post(`${adminUrl}delete-role`, data);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}

const deactivatedRoleApi = async (data: any) => {
    try {
        // Send a POST request to the "profile-update" endpoint with the provided data and token.
        const response = await axios.post(`${adminUrl}activate-deactivate-role`, data);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}

const addRoleApi = async (data: any) => {
    try {
        // Send a POST request to the "profile-update" endpoint with the provided data and token.
        const response = await axios.post(`${adminUrl}create-role`, data);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}

const getRoleDetailByIdApi = async (data: any) => {
    try {
        // Send a POST request to the "get-single-user detail" endpoint with the provided data and token.
        const response = await axios.post(`${adminUrl}get-role-details`, data);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}
const updateRoleDetail = async (data: any) => {
    try {
        // Send a POST request to the "get-single-user detail" endpoint with the provided data and token.
        const response = await axios.post(`${adminUrl}edit-role`, data);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}

const getAllModulesApi = async () => {
    try {
        // Send a POST request to the "get-single-user detail" endpoint with the provided data and token.
        const response = await axios.get(`${actUrl}get-all-modules`);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}





const RoleServices = {
    getRoleListApi,
    deleteRoleApi,
    addRoleApi,
    getRoleDetailByIdApi,
    updateRoleDetail,
    getAllModulesApi,
    deactivatedRoleApi
}

// Export the RoleServices class for use in other parts of the application.
export default RoleServices;
